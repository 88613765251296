<script>
export default {
    name: 'EmptyPage',
    data() {
        return {};
    },
};
</script>

<template>
  <div class="main-content">
    <div class="page-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col message text-center">
            <h4>{{ $t('messages.accessDenied') }}</h4>
            <h6>{{ $t('messages.no_company') }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.main-content {
    margin-left: 0;
}

.message {
    height: calc(100vh - 160px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
</style>
