import checkPermission from '@/js/helpers/checkPermission';

function checkAccess(el, binding) {
    const {value} = binding;

    if (!checkPermission(value)) {
        el.parentNode && el.parentNode.removeChild(el);
    }
}

export default {
    inserted(el, binding) {
        checkAccess(el, binding);
    },
    update(el, binding) {
        checkAccess(el, binding);
    },
};
