export const
    CREATED = 'created',
    CHANGED = 'changed',
    CONFIRMED = 'confirmed',
    MODAL_CLOSED = 'modal-closed',
    USER_CREATED = 'user-created',
    USER_CHANGED = 'user-changed',
    TIMER_FINISHED = 'timer_finished',
    COUNTRY_CREATED = 'country-created',
    FULFILLMENT_CREATED = 'fulfillment_created',
    FULFILLMENT_CHANGED = 'fulfillment_changed',
    WAREHOUSE_CREATED = 'warehouse_created',
    WAREHOUSE_CHANGED = 'warehouse_changed',
    PRODUCT_CREATED = 'product_created',
    INTEGRATION_CHANGED = 'integration_changed',
    NOTIFICATION_CHANGED = 'notification_changed',
    PRODUCT_CHANGED = 'product_changed',
    FILTERS_READY = 'filters-ready',
    FILTERS_APPLIES = 'filters-applies',
    APPLY_FILTER_START = 'apply-filter-start',
    APPLY_FILTER_STOP = 'apply-filter-stop',
    CLOSE_FILTER_WRAP = 'close-filter-wrap',
    HIDE_ONE_ITEM = 'hide-one-item',
    HIDE_ALL_ITEMS = 'hide-all-items';
