const ButtonPreloader = {
    bind(el, binding) {
        const span = document.createElement('span');
        span.innerHTML = el.innerHTML;
        el.innerHTML = '';
        el.appendChild(span);

        toggle(el, binding);
    },
    update(el, binding) {
        toggle(el, binding);
    },
};

function toggle(el, binding) {
    const preloaderClass = ['btn-preloader'];

    if (binding.modifiers.fill) {
        preloaderClass.push('fill');
    }

    if (binding.modifiers.dark) {
        preloaderClass.push('dark');
    }

    if (binding.modifiers.xs) {
        preloaderClass.push('xs');
    }

    if (binding.modifiers.right) {
        preloaderClass.push('right');
    }

    if (binding.value) {
        return el.classList.add(...preloaderClass);
    }

    el.classList.remove(...preloaderClass);
}

export default ButtonPreloader;
