const getDefaultState = () => {
    return {
        filters: [],
        onReady: null,
        onApply: null,
        loading: false,
    };
};

const emptyFilterValues = [null, undefined, '', []];

export default {
    namespaced: true,
    state: getDefaultState,
    getters: {
        getFilterValues: ({filters}) => (names = []) => {
            if (names.length && _.isArray(names)) {
                filters = filters.filter(filter => names.includes(filter.name));
            }

            return filters.reduce((acc, filter) => {
                if (emptyFilterValues.includes(filter.value)) {
                    return acc;
                }

                acc[filter.name] = filter.value;

                return acc;
            }, {});
        },
        getFilterValue: ({filters}) => (name) => {
            const filter = filters.find(filter => filter.name === name);

            return filter ? filter.value : null;
        },
        affectedCountableFilters({filters}) {
            return filters.filter(filter => filter.countable && !emptyFilterValues.includes(filter.value));
        },
        filterIsVisible: ({filters}) => (name) => {
            const filter = filters.find(filter => filter.name === name);

            return filter ? filter.visible : false;
        },
        loading: ({loading}) => loading,
    },
    mutations: {
        setFilters(state, filters) {
            state.filters = formatFiltersToInternalScheme(filters);
        },
        setCallbacks(state, {onReady, onApply}) {
            state.onReady = onReady;
            state.onApply = onApply;
        },
        initFilterValue(state, {name, value}) {
            const filter = state.filters.find(filter => filter.name === name);

            if (!filter) {
                throw new Error(`Filter ${name} is not registered in the store.`);
            }

            filter.value = value;
            filter.$ready = true;
        },
        updateFilterValue(state, {name, value}) {
            const filter = state.filters.find(filter => filter.name === name);

            if (filter) {
                filter.value = value;
            }
        },
        updateFilterUrl(state, name) {
            const filter = state.filters.find(filter => filter.name === name);

            if (filter) {
                const url = new URL(window.location.href);
                const searchParams = new URLSearchParams(url.search);

                if (!emptyFilterValues.includes(filter.value)) {
                    searchParams.set(name, filter.value);
                } else {
                    searchParams.delete(name);
                }

                url.search = searchParams.toString();
                window.history.replaceState({}, '', url.toString());
            }
        },
        modifyUrlWithAllFilters(state) {
            const url = new URL(window.location.href);
            const searchParams = new URLSearchParams(url.search);

            for (const filter of state.filters) {
                if (!emptyFilterValues.includes(filter.value)) {
                    searchParams.set(filter.name, filter.value);
                } else {
                    searchParams.delete(filter.name);
                }
            }

            url.search = searchParams.toString();
            window.history.pushState({}, '', url.toString());
        },
        clearFilterValues(state) {
            state.filters.forEach(filter => {
                if (!filter.clearable) {
                    return;
                }

                filter.value = null;
            });
        },
        toggleLoading(state, value) {
            state.loading = value;
        },
        resetState(state) {
            Object.assign(state, getDefaultState());
        },
    },
    actions: {
        setupFilters({commit}, filters) {
            commit('setFilters', filters);
        },
        setupFiltersCallbacks({commit}, {onReady, onApply}) {
            commit('setCallbacks', {onReady, onApply});
        },
        async initFilter({state, commit}, {name, value}) {
            commit('initFilterValue', {name, value});

            if (!emptyFilterValues.includes(value)) {
                commit('updateFilterUrl', name);
            }

            if (state.onReady && allVisibleFiltersReady(state.filters)) {
                commit('toggleLoading', true);

                await state.onReady();

                commit('toggleLoading', false);
            }
        },
        updateFilter({state, commit}, {name, value}) {
            commit('updateFilterValue', {name, value});

            const filter = state.filters.find(filter => filter.name === name);

            if (filter.onlyUrl) {
                commit('updateFilterUrl', name);
            }
        },
        async applyFilters({state, commit}) {
            commit('modifyUrlWithAllFilters');

            commit('toggleLoading', true);

            await state.onApply();

            commit('toggleLoading', false);
        },
        clearFilters({commit}) {
            commit('clearFilterValues');
        },
        resetState({commit}) {
            commit('resetState');
        },
    },
};

const formatFiltersToInternalScheme = (filters) => {
    return filters.map(filter => {
        if (!filter.name) {
            throw new Error('Filter must have a name');
        }

        return {
            name: filter.name,
            value: _.isUndefined(filter.value) ? null : filter.value,
            countable: filter.countable || false,
            visible: _.isUndefined(filter.visible) ? true : filter.visible,
            onlyUrl: _.isUndefined(filter.onlyUrl) ? false : filter.onlyUrl,
            clearable: _.isUndefined(filter.clearable) ? true : filter.clearable,
            $ready: filter.onlyUrl || false,
        };
    });
};

const allVisibleFiltersReady = (filters) => {
    const visibleFilters = filters.filter(filter => filter.visible);

    return visibleFilters.every(filter => filter.$ready);
};
