import authStorage from './authStorage';
import User from '@/js/models/User';
import Vuex from '@/js/store/index';

class AuthUser {
    async init() {
        const user = authStorage.getUserInfo;

        if (!user) {
            await this.setGlobalUserInfo();
        } else {
            await this.updateStore(user);
            this.setGlobalUserInfo();
        }

        return user;
    }

    async setGlobalUserInfo() {
        const user = await this.fetchUserInfo();
        this.updateStore(user);
        authStorage.setUserInfo(user);
    }

    async updateStore(user) {
        await Vuex.dispatch('UserModule/setUser', user);
    }

    async fetchUserInfo() {
        const query = {include: {companies: {}}};

        const {httpStatusCode, data, included} = await User.getAuthUser({query});

        if (httpStatusCode !== 200) {
            return {};
        }

        const {
            name,
            email,
            role,
            is_g2fa_enabled,
            previous_company_hash,
            current_company_hash,
        } = data['attributes'];

        const companies = included || [];
        const current_company = this.findCurrentCompany(companies, current_company_hash);

        return {
            hash: data.hash,
            name,
            email,
            role,
            previous_company_hash,
            current_company_hash,
            is_g2fa_enabled: Boolean(is_g2fa_enabled),
            companies,
            current_company,
        };
    }

    findCurrentCompany(companies, hash) {
        const company = companies?.find(el => el.hash === hash);

        return {
            hash: company?.hash,
            title: company?.attributes?.title,
        };
    }
}

export default new AuthUser();
