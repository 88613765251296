import BaseModel from '@/js/models/BaseModel';
import queryBuilder from '@/js/helpers/queryBuilder';

class Company extends BaseModel {
    get entity() {
        return 'companies';
    }

    getCompanyCountries = ({hash, ...params}) => {
        return this.api.get(`${this.baseUrl}/${hash}/countries${queryBuilder(params)}`);
    };

    attachCountry = ({company_hash, ...params}) => {
        return this.api.post(`${this.baseUrl}/${company_hash}/countries`, params);
    };

    changeStatus({hash, ...params}) {
        return this.api.put(`${this.baseUrl}/${hash}/changeStatus`, params);
    }

    changeErpSettings = ({company_hash, hash, ...params}) => {
        return this.api.put(`${this.baseUrl}/${company_hash}/countries/${hash}`, params);
    };
}

export default new Company();

