import {MODAL_CLOSED} from '@/js/domains/structures/common/events';

const ModalMixin = {
    watch: {
        is_visible(value) {
            value ? this.showModal(this.modal_id) : this.hideModal(this.modal_id);
        },
    },
    created() {
        document.addEventListener('keydown', this.closeModalByEscape);
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.closeModalByEscape);
    },
    methods: {
        showModal(modalId = this.modal_id) {
            return this.$bvModal.show(modalId);
        },
        hideModal(modalId = this.modal_id) {
            return this.$bvModal.hide(modalId);
        },
        close() {
            this.$emit(MODAL_CLOSED);
        },
        closeModalByEscape({code}) {
            if (code === 'Escape') {
                this.hideModal(this.modal_id);
            }
        },
    },
};

export default ModalMixin;
