import BaseModel from '@/js/models/BaseModel';

class System extends BaseModel {
    get entity() {
        return 'system';
    }

    timezones() {
        return this.api.get(`${this.baseUrl}/timezones`);
    }

    counters() {
        return this.api.get(`${this.baseUrl}/counters`);
    }
}

export default new System();
