import {
    BREAKPOINT_NAMES,
    MQ_DESKTOP_BREAKPOINTS,
    MQ_MOBILE_BREAKPOINTS,
} from '@/js/helpers/media_query/MediaQueryHelpers';

const mediaQueryGlobalMixin = {
    computed: {
        $mqIsMobile() {
            return MQ_MOBILE_BREAKPOINTS.includes(this.$mq);
        },
        $mqIsDesktop() {
            return MQ_DESKTOP_BREAKPOINTS.includes(this.$mq);
        },
        $mqIsXS() {
            return this.$mq === BREAKPOINT_NAMES.xs;
        },
        $mqIsSM() {
            return this.$mq === BREAKPOINT_NAMES.sm;
        },
        $mqIsMD() {
            return this.$mq === BREAKPOINT_NAMES.md;
        },
        $mqIsLG() {
            return this.$mq === BREAKPOINT_NAMES.lg;
        },
        $mqIsXL() {
            return this.$mq === BREAKPOINT_NAMES.xl;
        },
    },
};

export default mediaQueryGlobalMixin;
