import Vue from 'vue';
import vuelidate from '@/js/plugins/vuelidate';
import i18n from '@/js/plugins/i18n';
import '@/theme/design/index.scss';
import ClickOutside from '@/js/directives/ClickOutside';
import BlurContentPreloader from '@/js/directives/BlurContentPreloader';
import App from '@/views/App';
import store from '@/js/store';
import router from './router';
import menu_items from './router/menuItems';
import {mapActions, mapGetters} from 'vuex';
import Sentry from '@/js/plugins/Sentry';

Vue.directive('click-outside', ClickOutside);
Vue.directive('blur-content-preloader', BlurContentPreloader);

const app = {
    i18n,
    store,
    vuelidate,
    router,
    data: {menu_items},
    components: {
        App,
    },
    computed: {
        ...mapGetters('UserModule', ['authUser', 'company_hash', 'company_changed']),
    },
    watch: {
        authUser({hash}) {
            Sentry.init(hash);
        },
    },
    created() {
        const summary_update_time = 300 * 1000;
        this.loadSummary();
        setInterval(() => this.updateSummary(), summary_update_time);
    },
    methods: {
        ...mapActions(['loadSummary', 'updateSummary']),
    },
    render: h => h(App),
};

export default app;
