<script>
export default {
    name: 'DropdownItem',
    props: {
        icon: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: '14',
        },
        color: {
            type: String,
            default: '',
        },
        iconColor: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
    },
};
</script>

<template>
  <li>
    <a @click.prevent="$emit('click')" href="#" :class="`text-${color} dropdown-item`">
      <b-icon :icon="icon" :class="`font-size-${size} text-${iconColor} mr-1`"></b-icon>
      <slot v-if="$slots.default"></slot>
      <span v-else>{{ text }}</span>
    </a>
  </li>
</template>

<style scoped>
.dropdown-item {
    height: 31px;
    display: flex;
    align-items: center;
}
</style>

