import api from '@/js/bootstrap/axios/Axios';
import queryBuilder from '@/js/helpers/queryBuilder';

export default class BaseModel {
    constructor() {
        if (!this.entity) {
            throw new Error('Child class not provide entity');
        }

        this.api = api;
        // TODO move 'api' to axios
        this.baseUrl = `/api/${this.entity}`;
    }
    // TODO remove this methods
    index = (params = {}) => {
        return this.api.get(`${this.baseUrl}${queryBuilder(params)}`);
    };

    newIndex = (params = {}) => {
        return this.api.get(`${this.baseUrl}`, {params});
    };

    show = (hash, query = {}) => {
        return this.api.get(`${this.baseUrl}/${hash}${queryBuilder(query)}`);
    };

    newShow = (hash, params = {}) => {
        return this.api.get(`${this.baseUrl}/${hash}`, {params});
    };

    store = (params = {}, query = {}) => {
        return this.api.post(`${this.baseUrl}${queryBuilder(query)}`, params);
    };

    update = ({hash, ...params}, query = {}) => {
        return this.api.put(`${this.baseUrl}/${hash}${queryBuilder(query)}`, params);
    };

    destroy = (hash) => {
        return this.api.delete(`${this.baseUrl}/${hash}`);
    };
}
