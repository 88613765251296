/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from 'vue';
import vuelidate from '@/js/plugins/vuelidate';
import i18n from '@/js/plugins/i18n';
import ClickOutside from '@/js/directives/ClickOutside';
import BlurContentPreloader from '@/js/directives/BlurContentPreloader';
import App from '@/views/App';
import store from '@/js/store';
import router from './router';
import menu_items from './router/menuItems';
import {mapGetters} from 'vuex';
import Sentry from '@/js/plugins/Sentry';

Vue.directive('click-outside', ClickOutside);
Vue.directive('blur-content-preloader', BlurContentPreloader);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = {
    i18n,
    store,
    vuelidate,
    router,
    data: {menu_items},
    components: {
        App,
    },
    computed: {
        ...mapGetters('UserModule', ['authUser']),
    },
    watch: {
        authUser({hash}) {
            Sentry.init(hash);
        },
    },
    render: h => h(App),
};

export default app;
