<script>
import UserLoginer from '@/js/domains/user/UserLoginer';
import {mapActions, mapGetters} from 'vuex';
import DropdownItem from '@/js/components/DropdownItem';
import {ACCOUNT, ADMIN, USER} from '@/js/domains/structures/common/roles';
import PageHeader from '@/js/components/PageHeader';
import Auth from '@/js/models/Auth';
import authStorage from '@/js/auth/authStorage';
import {LOGIN} from '@/js/domains/structures/login/routes';
import ROUTE_NAMES from '@/js/domains/structures/common/RouteNames';

export default {
    name: 'Navbar',
    components: {
        DropdownItem,
        PageHeader,
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters('UserModule', {
            authUser: 'authUser',
            role: 'role',
            admin: 'admin',
            user: 'user',
            companies: 'companies',
            company_hash: 'company_hash',
            is_user_company: 'is_user_company',
            available_companies: 'available_companies',
        }),
        ...mapGetters('HeaderModule', {
            page_title: 'page_title',
            breadcrumbs: 'breadcrumbs',
        }),
        roleName() {
            switch (this.role) {
                case ADMIN:
                    return this.$t('admin');
                case USER:
                    return this.$tc('user');
                case ACCOUNT:
                    return this.$tc('account');
                default:
                    return '';
            }
        },
        dropdownAvailableCompanies() {
            if (this.available_companies.length > 2) {
                return this.available_companies.slice(0, 1);
            }

            return this.available_companies.slice(0, 2);
        },
        toSettings: () => ({name: ROUTE_NAMES.userSettings}),
    },
    created() {
        if (localStorage.getItem('sidebar') === 'false') {
            if (window.screen.width >= 992) {
                document.body.classList.add('sidebar-enable');
                document.body.classList.toggle('vertical-collpsed');
            } else {
                document.body.classList.remove('vertical-collpsed');
            }
        }

        this.$router.afterEach(() => {
            if (window.screen.width >= 992) {
                return;
            }

            document.body.classList.remove('sidebar-enable', 'vertical-collpsed');
        });
    },
    methods: {
        ...mapActions('UserModule', ['changeCurrentCompany']),
        logout() {
            Auth.logout();
            authStorage.clearAll();
            location.href = `/${LOGIN}`;
        },
        async logoutAsUser(hash) {
            await UserLoginer.logoutAsUser(hash);
        },
        toggleMenu() {
            if (document.body.classList.contains('sidebar-enable')) {
                localStorage.setItem('sidebar', 'true');
            } else {
                localStorage.setItem('sidebar', 'false');
            }

            document.body.classList.toggle('sidebar-enable');

            if (window.screen.width >= 992) {
                document.body.classList.toggle('vertical-collpsed');
            } else {
                document.body.classList.remove('vertical-collpsed');
            }
        },
    },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <div class="navbar-brand-box text-left">
          <router-link to="/" class="logo logo-light">
            <span class="logo-sm text-light font-size-16">{{ $t('cod') }}</span>
            <span class="logo logo-lg text-light font-size-16">{{ $t('crm_name') }}</span>
          </router-link>
        </div>

        <button

          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <b-icon icon="justify" shift-v="2"></b-icon>
        </button>

      </div>

      <page-header :title="page_title" :breadcrumbs="breadcrumbs"></page-header>

      <div class="d-flex align-items-center ml-auto">
        <b-dropdown right variant="black" toggle-class="header-item" class="header-dropdown">
          <template v-slot:button-content>
            <span>{{ authUser.email }}</span>
            <b-icon icon="chevron-down" shift-v="4" class="font-size-11 ml-1"></b-icon>
            <div class="d-flex align-items-center justify-content-end">
              <span class="mr-xl-3">
                <span v-if="user">{{ authUser.company_title }} | </span> {{ roleName }}
              </span>
            </div>
          </template>
          <!--     @todo b-dropdown-item :to prop generates warning "<router-link>'s tag prop is deprecated"    -->
          <!--     @todo Find a way to fix it. Maybe update the package or use custom slot template    -->
          <b-dropdown-item v-if="admin || authUser.company_hash" :to="toSettings">
            <b-icon icon="gear" class="mr-1 font-size-16"></b-icon>
            <span>{{ $t('settings') }}</span>
          </b-dropdown-item>

          <template v-if="authUser.company_hash">
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item disabled>
              <b-icon icon="building" class="mr-1 font-size-16"></b-icon>
              {{ authUser.company_title }}
              <b-badge variant="success" class="ml-1"> {{ $t('current') }} {{ $tc('company').toLowerCase() }}</b-badge>
            </b-dropdown-item>
            <b-dropdown-item
              v-for="{hash, title} in dropdownAvailableCompanies"
              :key="hash"
              @click="changeCurrentCompany(hash)"
            >
              <b-icon icon="building" class="mr-1 font-size-16"></b-icon>
              <span>{{ title }}</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="available_companies.length > 2" @click="$emit('show_change_company_modal')">
              <b-icon icon="building" class="mr-1 font-size-16"></b-icon>
              {{ $t('all') }} {{ $tc('company', 2).toLowerCase() }}
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
          </template>

          <dropdown-item
            :text="$t('logout')"
            icon="box-arrow-in-right"
            size="16"
            color="danger"
            @click="logout"
          ></dropdown-item>
        </b-dropdown>
      </div>

    </div>
  </header>
</template>

<style>
.sidebar-enable.vertical-collpsed .navbar-brand-box {
    padding: 0 .5rem;
}

.header-dropdown .btn.dropdown-toggle {
    text-align: right;
}

.vertical-collpsed .logo {
    text-align: center;
}

.navbar-header .dropdown-item {
    display: flex;
    align-items: center;
}
</style>
