import i18n from '@/js/plugins/i18n';

export const
    ADMIN = 'admin',
    USER = 'user',
    ACCOUNT = 'account';

export const FILTERABLE_ROLE = [
    {value: ACCOUNT, title: i18n.t('account')},
    {value: USER, title: i18n.tc('user')},
];