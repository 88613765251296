import Vue from 'vue';

require('@/js/plugins/notification').default;
require('@/js/bootstrap/vue').default;
require('@/js/plugins/ladda').default;

require('@/theme/design/index.scss').default;
require('@/sass/app.scss').default;
window.event_bus = new Vue();

window._ = require('lodash');
window.Vue = Vue;
window.App = {
    user: null,
};
