export const BREAKPOINT_NAMES = {
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
};

export const MQ_MOBILE_BREAKPOINTS = [BREAKPOINT_NAMES.xs, BREAKPOINT_NAMES.sm, BREAKPOINT_NAMES.md];
export const MQ_DESKTOP_BREAKPOINTS = [BREAKPOINT_NAMES.lg, BREAKPOINT_NAMES.xl];

export const BREAKPOINTS = {
    [BREAKPOINT_NAMES.xs]: 576,
    [BREAKPOINT_NAMES.sm]: 768,
    [BREAKPOINT_NAMES.md]: 992,
    [BREAKPOINT_NAMES.lg]: 1200,
    [BREAKPOINT_NAMES.xl]: Infinity,
};
