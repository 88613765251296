import User from "@/js/models/User";

class UserLoginer {
    async loginAsUser(user_hash) {
        await User.loginAsUser(user_hash);
        window.open(window.location.hostname);
        window.close();
    }

    async logoutAsUser(user_hash) {
        await User.logoutAsUser(user_hash);

        location.reload();
    }
}

export default new UserLoginer();
