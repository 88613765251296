export class AccessDeniedError extends Error {
    constructor(message, response) {
        super(message);
        this.name = 'AccessDeniedError';
        this.response = response;
    }
}

export class UnauthorizedError extends Error {
    constructor(message, response) {
        super(message);
        this.name = 'UnauthorizedError';
        this.response = response;
    }
}

export class FailedValidationError extends Error {
    constructor(message, response) {
        super(message);
        this.name = 'FailedValidationError';
        this.response = response;
    }
}

export class BadRequestError extends Error {
    constructor(message, response) {
        super(message);
        this.name = 'BadRequestError';
        this.response = response;
    }
}

export class NotFoundError extends Error {
    constructor(message, response) {
        super(message);
        this.name = 'NotFoundError';
        this.response = response;
    }
}

export class NoResponseError extends Error {
    constructor(message, response) {
        super(message);
        this.name = 'NoResponseError';
        this.response = response;
    }
}

export class QueryLimitExceededError extends Error {
    constructor(message, response) {
        super(message);
        this.name = 'QueryLimitExceededError';
        this.response = response;
    }
}

export class InternalServerError extends Error {
    constructor(message, response) {
        super(message);
        this.name = 'InternalServerError';
        this.response = response;
    }
}

export class LockedError extends Error {
    constructor(message, response) {
        super(message);
        this.name = 'LockedError';
        this.response = response;
    }
}
