<script>
import {mapActions, mapGetters} from 'vuex';
import Navbar from '@/js/components/Navbar';
import Sidebar from '@/js/components/Sidebar';
import EmptyPage from '@/pages/user/empty-page/EmptyPage';
import ChangeCompanyModal from '@/js/domains/user/components/ChangeCompanyModal';

export default {
    name: 'App',
    components: {
        Navbar,
        Sidebar,
        EmptyPage,
        ChangeCompanyModal,
    },
    data() {
        return {
            show_change_company_modal: false,
        };
    },
    computed: {
        ...mapGetters('UserModule', {
            is_user_company: 'is_user_company',
            company_hash: 'company_hash',
            admin: 'admin',
            companies: 'companies',
        }),
    },
    methods: {
        ...mapActions('UserModule', {
            setUser: 'setUser',
        }),
    },
};
</script>

<template>
  <div id="app">
    <div layout="vertical">
      <div id="layout-wrapper">
        <navbar @show_change_company_modal="show_change_company_modal = true"></navbar>
        <template v-if="is_user_company">
          <change-company-modal
            v-if="companies.length"
            :is_visible="show_change_company_modal"
            @modal-closed="show_change_company_modal = false"
          >
          </change-company-modal>
          <sidebar></sidebar>
          <div class="main-content">
            <div class="page-content">
              <div class="container-fluid">
                <router-view></router-view>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <EmptyPage></EmptyPage>
        </template>
      </div>
    </div>
  </div>
</template>
