import i18n from '@/js/plugins/i18n';
import createRouter from '@/js/helpers/createRouter';
import ROUTE_NAMES from '@/js/domains/structures/common/RouteNames';

const routes = {
    redirect: [
        {
            path: '*',
            redirect: {name: ROUTE_NAMES.login},
        },
    ],

    main: [
        {
            path: `/login/`,
            name: ROUTE_NAMES.login,
            meta: {
                title: i18n.tc('login', 1),
            },
            component: () => import('@/pages/login/Login'),
        },
    ],

    nested: [],
};

const router = createRouter(routes);

export default router;
