import Vue from 'vue';
import i18n from '@/js/plugins/i18n';
import VueRouter from 'vue-router';
import _ from 'lodash';
import store from '@/js/store';

const routerPush = VueRouter.prototype.push;
const routerReplace = VueRouter.prototype.push;

const isParamsEqual = (param, next_param) => {
    return !param || !next_param || _.isEqual(next_param, param);
};

const isNavigationDuplicated = (current_route, next_route) => {
    const {path: next_path, name: next_name, params: next_params = {}, query: next_query = {}} = next_route;
    const {path, name, params, query} = current_route;

    return (
        _.isEqual(next_query, query) &&
        _.isEqual(next_params, params) &&
        isParamsEqual(path, next_path) &&
        isParamsEqual(name, next_name)
    );
};

const isDefaultPage = ({routes}, current_route, location) => {
    if (location.path !== '/') {
        return false;
    }

    const default_page = routes.find((route) => !route.path.length);
    const default_name = default_page?.redirect.name;
    const current_name = current_route?.name;

    return default_name && current_name && default_name === current_name;
};

VueRouter.prototype.push = function push(location) {
    const is_default_route = isDefaultPage(this.options, this.currentRoute, location);

    if (!isNavigationDuplicated(this.currentRoute, location) && !is_default_route) {
        return routerPush.call(this, location);
    }
};

VueRouter.prototype.replace = function replace(location) {
    if (!isNavigationDuplicated(this.currentRoute, location)) {
        return routerReplace.call(this, location);
    }
};

const createRouter = routes => {
    const router = new VueRouter({
        mode: 'history',
        linkActiveClass: 'active',
        routes: [
            ...routes.main,
            ...routes.nested,
            ...routes.redirect,
        ],
    });

    const title_suffix = i18n.t('crm_name');

    router.afterEach((to) => {
        Vue.nextTick(() => {
            document.title = `${to.meta.title ? to.meta.title + ' |' : ''} ${title_suffix}`;
        });

        store.dispatch('FiltersManagerModule/resetState');
    });

    return router;
};

Vue.use(VueRouter);

export default createRouter;
