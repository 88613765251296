<script>
import MetisMenu from 'metismenujs/dist/metismenujs';
import simplebar from 'simplebar-vue';
import {mapGetters} from 'vuex';

export default {
    name: 'Sidenav',
    components: {simplebar},
    data() {
        return {};
    },
    computed: {
        ...mapGetters('FailedJobModule', ['summary']),
    },
    mounted: function () {
        new MetisMenu('#side-menu');
        const menu = document.querySelector('#side-menu');

        this.$router.afterEach((to) => {
            const navLinks = menu.querySelectorAll('.mm-active');
            const subLinks = menu.querySelectorAll('.mm-active li a');
            const subMenu = menu.querySelectorAll('.sub-menu');

            navLinks.forEach(el => {
                el.classList.remove('mm-active');
            });

            subLinks.forEach(el => {
                el.classList.remove('active');
            });

            subMenu.forEach(el => {
                el.classList.remove('mm-show');
            });

            if (to.meta.parent) {
                const parentLink = menu.querySelector(`[href='/${to.meta.parent}']`);
                parentLink.parentElement.classList.add('mm-active');
                parentLink.classList.add('active');
            }
            const links = menu.getElementsByClassName('side-nav-link-ref');
            let matchingMenuItem = null;
            const paths = [];

            for (let i = 0; i < links.length; i++) {
                paths.push(links[i]['pathname']);
            }
            const itemIndex = paths.indexOf(window.location.pathname);

            if (itemIndex === -1) {
                const strIndex = window.location.pathname.lastIndexOf('/');
                const item = window.location.pathname.substr(0, strIndex).toString();
                matchingMenuItem = links[paths.indexOf(item)];
            } else {
                matchingMenuItem = links[itemIndex];
            }

            if (matchingMenuItem) {
                matchingMenuItem.classList.add('active');

                const parent = matchingMenuItem.parentElement;

                if (parent) {
                    parent.classList.add('mm-active');
                    const parent2 = parent.parentElement.closest('ul');

                    if (parent2 && parent2.id !== 'side-menu') {
                        parent2.classList.add('mm-show');

                        const parent3 = parent2.parentElement;

                        if (parent3) {
                            parent3.classList.add('mm-active');
                            var childAnchor = parent3.querySelector('.has-arrow');
                            var childDropdown = parent3.querySelector('.has-dropdown');

                            if (childAnchor) {
                                childAnchor.classList.add('mm-active');
                            }

                            if (childDropdown) {
                                childDropdown.classList.add('mm-active');
                            }

                            const parent4 = parent3.parentElement;

                            if (parent4 && parent4.id !== 'side-menu') {
                                parent4.classList.add('mm-show');
                                const parent5 = parent4.parentElement;

                                if (parent5 && parent5.id !== 'side-menu') {
                                    parent5.classList.add('mm-active');
                                    const childanchor = parent5.querySelector('.is-parent');

                                    if (childanchor && parent5.id !== 'side-menu') {
                                        childanchor.classList.add('mm-active');
                                    }
                                }
                            }
                        }
                    }
                }
            }
        });
    },
    methods: {
        hasItems(item) {
            return item.subItems !== undefined ? item.subItems.length > 0 : false;
        },
        showSummaryBadge(summary_total) {
            return summary_total && this.summary.total > 0;
        },
        changeRoute(link) {
            if (link !== this.$route.path) {
                return this.$router.push({path: link});
            }

            location.reload();
        },
    },
};
</script>

<template>
  <div class="vertical-menu">
    <simplebar class="h-100">
      <div id="sidebar-menu">
        <ul id="side-menu" class="metismenu list-unstyled">
          <template v-for="item in $root.menu_items">
            <li v-if="item.isTitle" class="menu-title" :key="item.label">{{ item.label }}</li>
            <li v-if="!item.isTitle && !item.isLayout" :key="item.label">
              <a
                v-if="hasItems(item)"
                :class="{'has-arrow': !item.badge, 'has-dropdown': item.badge}"
                href="javascript:void(0);"
                class="is-parent"
              >
                <b-icon v-if="item.icon" :icon="item.icon" class="font-size-16 mr-2"></b-icon>
                <span>{{ item.label }}</span>
                <span v-if="showSummaryBadge(item.summary_total)" :class="`badge badge-pill badge-success`">
                  {{ summary.total }}
                </span>
                <span v-if="item.badge" :class="`badge badge-pill badge-${item.badge.variant} float-right`">
                  item.badge.text
                </span>
              </a>

              <a
                v-if="!hasItems(item)"
                @click.prevent="changeRoute(item.link)"
                :href="item.link"
                :data-link="item.link.substring(1)"
                class="side-nav-link-ref"
              >
                <b-icon v-if="item.icon" :icon="item.icon" class="font-size-16 mr-2"></b-icon>
                <span>{{ item.label }}</span>
                <span v-if="item.badge" :class="`badge badge-pill badge-${item.badge.variant} float-right`">
                  {{ item.badge.text }}
                </span>
              </a>

              <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li v-for="(subitem) of item.subItems" :key="subitem.label">
                  <a
                    v-if="!hasItems(subitem)"
                    @click.prevent="changeRoute(subitem.link)"
                    :href="subitem.link"
                    class="side-nav-link-ref"
                  >
                    {{ subitem.label }}
                    <span
                      v-if="showSummaryBadge(subitem.summary_total)"
                      class="badge badge-pill badge-success"
                    >
                      {{ summary.total }}
                    </span>
                  </a>
                  <a
                    v-if="hasItems(subitem)"
                    class="side-nav-link-a-ref has-arrow"
                    href="javascript:void(0);"
                  >{{ subitem.label }}</a>
                  <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li v-for="(subSubitem) of subitem.subItems" :key="subSubitem.label">
                      <router-link
                        :to="subSubitem.link"
                        class="side-nav-link-ref"
                      >{{ subSubitem.label }}
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </div>
    </simplebar>
  </div>
</template>
