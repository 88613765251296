import Vue from 'vue';
import vuelidate from '@/js/plugins/vuelidate';
import i18n from '@/js/plugins/i18n';
import ClickOutside from '@/js/directives/ClickOutside';
import BlurContentPreloader from '@/js/directives/BlurContentPreloader';
import App from '@/views/App';
import store from '@/js/store';
import router from './router';
import menu_items from './router/menuItems';
import {mapGetters} from 'vuex';
import Sentry from '@/js/plugins/Sentry';

Vue.directive('click-outside', ClickOutside);
Vue.directive('blur-content-preloader', BlurContentPreloader);

const app = {
    i18n,
    store,
    vuelidate,
    router,
    data: {menu_items},
    components: {
        App,
    },
    computed: {
        ...mapGetters('UserModule', ['authUser']),
    },
    watch: {
        authUser({hash}) {
            Sentry.init(hash);
        },
    },
    render: h => h(App),
};

export default app;
