export default {
    bind(el, binding) {
        if (!binding.value) {
            return el.classList.remove('blur-content-preloader', 'vertical-center');
        }

        if (binding.modifiers.vCenter || el.clientHeight <= 400) {
            return el.classList.add('blur-content-preloader', 'vertical-center');
        }

        el.classList.add('blur-content-preloader');
    },

    update(el, binding) {
        if (!binding.value) {
            return el.classList.remove('blur-content-preloader', 'vertical-center');
        }

        if (binding.modifiers.vCenter || el.clientHeight <= 400) {
            return el.classList.add('blur-content-preloader', 'vertical-center');
        }

        el.classList.add('blur-content-preloader');
    },
};
