import CallCenterClass from '@/js/models/CallCenterClass';

export default {
    namespaced: true,
    state: {
        callcenters: [],
        callcentersLoading: false,
        callcentersLoaded: false,
    },
    getters: {
        callcenters: ({callcenters}) => callcenters,
    },
    mutations: {
        updateCallcenters(state, payload) {
            state.callcenters = payload;
        },
        setCallcentersLoading(state, payload) {
            state.callcentersLoading = payload;
        },
        setCallcentersLoaded(state, payload) {
            state.callcentersLoaded = payload;
        },
    },
    actions: {
        updateCallcenters({dispatch, commit}, params = {}) {
            commit('setCallcentersLoaded', false);
            dispatch('loadCallcenters', params);
            commit('setCallcentersLoaded', true);
        },
        async loadCallcenters({commit, state}, params = {}) {
            if (state.callcentersLoading || state.callcentersLoaded) {
                return;
            }

            commit('setCallcentersLoading', true);

            try {
                const {data} = await CallCenterClass.newIndex({sortBy: 'title', ...params});

                commit('updateCallcenters', data);
            } finally {
                commit('setCallcentersLoading', false);
            }
        },
    },
};
