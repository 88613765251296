import i18n from '@/js/plugins/i18n';
import {
    AccessDeniedError,
    BadRequestError,
    FailedValidationError,
    InternalServerError,
    LockedError,
    NoResponseError,
    NotFoundError,
    QueryLimitExceededError,
    UnauthorizedError,
} from '@/js/bootstrap/axios/Errors';
import authStorage from '@/js/auth/authStorage';
import {LOGIN} from '@/js/domains/structures/login/routes';
import Vue from 'vue';

const vue = new Vue;

class AxiosErrorHandler {
    constructor(error) {
        this.error = error;
    }

    get noResponseError() {
        return !this.error || !this.error.response;
    }

    get badRequestError() {
        return this.error.response.status === 400;
    }

    get unauthorizedError() {
        return this.error.response.status === 401;
    }

    get accessDeniedError() {
        return this.error.response.status === 403;
    }

    get notFoundError() {
        return this.error.response.status === 404;
    }

    get failedValidationError() {
        return this.error.response.status === 422;
    }

    get lockedError() {
        return this.error.response.status === 423;
    }

    get queryLimitExceeded() {
        return this.error.response.status === 429;
    }

    get internalServerError() {
        return this.error.response.status === 500;
    }

    handle() {
        // Login page case
        if (!authStorage.getUserInfo) {
            return Promise.reject(this.error);
        }

        if (this.noResponseError) {
            return this.handleNoResponseError();
        }

        if (this.badRequestError) {
            return this.handleBadRequestError();
        }

        if (this.queryLimitExceeded) {
            return this.handleQueryLimitExceededError();
        }

        if (this.unauthorizedError) {
            return this.handleUnauthorizedError();
        }

        if (this.accessDeniedError) {
            return this.handleAccessDeniedError();
        }

        if (this.notFoundError) {
            return this.handleNotFoundError();
        }

        if (this.internalServerError) {
            return this.handleInternalServerError();
        }

        if (this.failedValidationError) {
            return this.handleFailedValidationError();
        }

        if (this.lockedError) {
            return this.handleLockedError();
        }

        throw this.error;
    }

    handleNoResponseError() {
        vue.$notification.error(i18n.tc('messages.somethingWrong'));

        throw new NoResponseError(i18n.tc('messages.noResponseFromServer'), this.error);
    }

    handleQueryLimitExceededError() {
        vue.$notification.error(i18n.t('messages.queryLimitExceeded'));
        const error = this.error.response.data;

        throw new QueryLimitExceededError(error.message, error);
    }

    handleAccessDeniedError() {
        vue.$notification.error(i18n.t('messages.accessDenied'));
        const error = this.error.response.data;

        throw new AccessDeniedError(error.message, error);
    }

    handleInternalServerError() {
        const message = i18n.t('messages.internalServerError');
        vue.$notification.error(message);

        return new InternalServerError(message, this.error);
    }

    handleUnauthorizedError() {
        authStorage.clearAll();
        location.href = `/${LOGIN}`;

        const error = this.error.response.data;

        throw new UnauthorizedError(error.message, error);
    }

    handleFailedValidationError() {
        const error = this.error.response.data;

        vue.$notification.error(error.message);

        throw new FailedValidationError(error.message, error);
    }

    handleBadRequestError() {
        const error = this.error.response.data;

        throw new BadRequestError(error.message, error);
    }

    handleNotFoundError() {
        const error = this.error.response.data;

        throw new NotFoundError(error.message, error);
    }

    handleLockedError() {
        const error = this.error.response.data;

        throw new LockedError(error.message, error);
    }
}

export default AxiosErrorHandler;
