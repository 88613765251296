import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import {
    DEFAULT_NOTIFICATION_POSITION,
    DEFAULT_NOTIFICATION_TIMEOUT,
    ERROR_NOTIFICATION_TIMEOUT,
} from '@/js/domains/structures/common/notification';

Vue.use(VueToast, {
    position: DEFAULT_NOTIFICATION_POSITION,
    duration: DEFAULT_NOTIFICATION_TIMEOUT,
});

class Notifications {
    success(message) {
        Vue.$toast.success(this.#transformText(message));
    }

    #transformText(text) {
        return text.toLowerCase();
    }

    error(message) {
        Vue.$toast.error(this.#transformText(message), {
            duration: ERROR_NOTIFICATION_TIMEOUT,
        });
    }

    warning(message) {
        Vue.$toast.warning(this.#transformText(message));
    }

    info(message) {
        Vue.$toast.info(this.#transformText(message));
    }
}

const notification = new Notifications();

Vue.prototype.$notification = notification;

export default notification;
