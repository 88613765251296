import store from '@/js/store';

export default function checkPermission(value) {
    if (value && value instanceof Array && value.length > 0) {
        const role = store.getters['UserModule/role'];
        const permission_roles = value;

        return permission_roles.includes(role);
    } else {
        console.error(`need roles! Like "['admin','user','account']"`);

        return false;
    }
}
