import BaseModel from '@/js/models/BaseModel';
import INTEGRATION_TYPES from '@/js/domains/Integration/Structures/integration_types';

class Affiliate extends BaseModel {
    get entity() {
        return 'affiliates';
    }

    generateApiKey() {
        return this.api.post(`/api/integration/api-key/generate`, {type: INTEGRATION_TYPES.affiliate});
    }
}

export default new Affiliate();
