import User from '@/js/models/User';
import {ADMIN, USER} from '@/js/domains/structures/common/roles';
import formatDate from '@/js/helpers/formatDate';
import router from '@/pages/user/router';
import ROUTE_NAMES from '@/js/domains/structures/common/RouteNames';

export default {
    namespaced: true,
    state: {
        user: {
            hash: '',
            name: '',
            role: '',
            status: '',
            company_hash: '',
            previous_company_hash: '',
            company_title: '',
            is_g2fa_enabled: '',
            companies: [],
        },
        permissions: [],
    },
    getters: {
        authUser: ({user}) => user,
        role: ({user}) => user.role,
        admin: ({user}) => user.role === ADMIN,
        user: ({user}) => user.role === USER,
        company_hash: ({user}) => user.company_hash,
        is_user_company: ({user}, getters) => getters.user ? getters.user && user.company_hash : true,
        isPermissions: ({permissions}) => !!permissions.length,
        hasPermission: ({permissions}) => (action) => {
            return permissions.some(el => el === action);
        },
        companies: ({user}) => user.companies.map(company => {
            return {
                ...company,
                active: company.hash === user.company_hash,
                created_at: formatDate(company.created_at),
            };
        }),
        available_companies: ({user}, getters) => {
            const companies = getters.companies.filter(({hash}) => hash !== getters.company_hash);

            if (user.previous_company_hash) {
                return companies.sort(({hash}) => hash === user.previous_company_hash && -1);
            }

            return companies;
        },
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload;
        },
        setPermissions(state, payload) {
            state.permissions = payload;
        },
    },
    actions: {
        async setUser({commit}, user) {
            const companies = user.companies.map(({hash, attributes}) => ({hash, ...attributes}));

            const {hash: company_hash, title: company_title} = user.current_company;

            commit('setUser', {...user, company_hash, ...(company_title && {company_title}), companies});

            const {data: {permissions}} = await User.getPermissions();
            commit('setPermissions', permissions);
        },
        async changeCurrentCompany(store, hash) {
            await User.changeCurrentCompany(hash);

            location.href = router.resolve(ROUTE_NAMES.flows).href;
        },
    },
};
