export default {
    namespaced: true,
    state: {
        page_title: '',
        breadcrumbs: [],
    },
    getters: {
        page_title: ({page_title}) => page_title,
        breadcrumbs: ({breadcrumbs}) => breadcrumbs,

    },
    mutations: {
        setHeaderData(state, {page_title = '', breadcrumbs = []}) {
            state.page_title = page_title;
            state.breadcrumbs = breadcrumbs;
        },
    },
    actions: {
        setHeaderData({commit}, payload = {}) {
            commit('setHeaderData', payload);
        },
    },
};
