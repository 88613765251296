import i18n from '@/js/plugins/i18n';
import Sentry from '@/js/plugins/Sentry';


import App from '@/views/auth/LoginApp';
import router from './router';

const app = {
    i18n,
    router,
    components: {App},
    render: h => h(App),
    created() {
        Sentry.init();
    },
};

export default app;
