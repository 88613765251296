import i18n from '@/js/plugins/i18n';
import router from '@/pages/user/router';
import ROUTE_NAMES from '@/js/domains/structures/common/RouteNames';

const menu_items = [
    {
        label: i18n.tc('flow', 2),
        icon: 'shuffle',
        link: router.resolve({name: ROUTE_NAMES.flows}).href,
    },
    {
        label: i18n.tc('crm'),
        link: router.resolve({name: ROUTE_NAMES.crm}).href,
        icon: 'file-spreadsheet',
    },
    {
        label: i18n.tc('report', 2),
        icon: 'bar-chart-steps',
        subItems: [
            {
                label: i18n.tc('status', 2),
                link: router.resolve({name: ROUTE_NAMES.statusesReport}).href,
            },
            {
                label: i18n.tc('logistic', 2),
                link: router.resolve({name: ROUTE_NAMES.logisticsReport}).href,
            },
        ]
    },
    {
        label: i18n.tc('product', 2),
        icon: 'archive',
        link: router.resolve({name: ROUTE_NAMES.products}).href,
    },
    {
        label: i18n.tc('affiliate', 2),
        link: router.resolve({name: ROUTE_NAMES.affiliates}).href,
        icon: 'shop-window',
    },
    {
        label: i18n.tc('callCenter'),
        link: router.resolve({name: ROUTE_NAMES.callCenter}).href,
        icon: 'telephone',
    },
    {
        label: i18n.tc('country', 2),
        link: router.resolve({name: ROUTE_NAMES.countries}).href,
        icon: 'globe',
    },
    {
        label: i18n.tc('fulfillment', 2),
        icon: 'inboxes',
        link: router.resolve({name: ROUTE_NAMES.fulfillments}).href,
    },
    {
        label: i18n.tc('tool', 2),
        icon: 'gear',
        summary_total: true,
        subItems: [
            {
                label: router.resolve({name: ROUTE_NAMES.infoCenter}).route.meta.title,
                link: router.resolve({name: ROUTE_NAMES.infoCenter}).href,
                summary_total: true,
            },
            {
                label: router.resolve({name: ROUTE_NAMES.httpClientErrorsLogs}).route.meta.title,
                link: router.resolve({name: ROUTE_NAMES.httpClientErrorsLogs}).href,
            },
        ],
    },
];

export default menu_items;
