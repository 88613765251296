const ROUTE_NAMES = {
    login: 'login',
    crm: 'crm',
    order: 'order',
    empty: 'empty',
    flows: 'flows',
    targets: 'targets',
    products: 'products',
    employees: 'employees',
    countries: 'countries',
    orderLog: 'orderLog',
    affiliates: 'affiliates',
    infoCenter: 'infoCenter',
    callCenter: 'callCenter',
    notification: 'notification',
    fulfillments: 'fulfillments',
    statusesReport: 'statusesReport',
    logisticsReport: 'logisticsReport',
    httpClientErrorsLogs: 'httpClientErrorsLogs',
    userSettings: 'userSettings',
    users: 'users',
    companies: 'companies',
    fulfillmentProducts: 'fulfillmentProducts',
    fulfillmentCouriers: 'fulfillmentCouriers',
};

export default ROUTE_NAMES;
