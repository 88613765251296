class AuthStorage {
    clearAll() {
        localStorage.removeItem('user_role');
        localStorage.removeItem('user_info');
        localStorage.removeItem('access_token');
    }

    setUserRole(user_role) {
        return window.localStorage.setItem('user_role', user_role);
    }

    get getUserRole() {
        return window.localStorage.getItem('user_role');
    }

    setUserInfo(user_info) {
        return window.localStorage.setItem('user_info', JSON.stringify(user_info));
    }

    get getUserInfo() {
        const user = window.localStorage.getItem('user_info');
        return user ? JSON.parse(user) : null;
    }
}

export default new AuthStorage();
