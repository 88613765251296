import AxiosErrorHandler from '@/js/bootstrap/axios/AxiosErrorHandler';
import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API_HOST;
axios.defaults.headers.common = {'X-Requested-With': 'XMLHttpRequest'};
axios.defaults.withCredentials = true;

const api = axios.create();

api.interceptors.response.use(
    function (response) {
        return Promise.resolve({...response.data, httpStatusCode: response.status});
    },
    function (error) {
        const handler = new AxiosErrorHandler(error);

        return handler.handle();
    },
);

export default api;
