import {BREAKPOINTS} from '@/js/helpers/media_query/MediaQueryHelpers';
import Vue from 'vue';
import BootstrapVue, {BootstrapVueIcons} from 'bootstrap-vue';
import NotificationsMixin from '@/js/mixins/NotificationsMixin';
import '@/theme/design/index.scss';
import {ACCOUNT, ADMIN, USER} from '@/js/domains/structures/common/roles';
import AdminApp from '@/pages/admin/app';
import UserApp from '@/pages/user/app';
import AccountApp from '@/pages/account/app';
import LoginApp from '@/pages/login/app';
import authUser from '@/js/auth/authUser';
import authStorage from '@/js/auth/authStorage';
import permission from '@/js/directives/permission';
import FrontendComponents from '@frontend/components';
import MediaQueryGlobalMixin from '@/js/helpers/media_query/MediaQueryGlobalMixin';

require('@/js/bootstrap/bootstrap');

Vue.directive('permission', permission);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.use(FrontendComponents, {vue_mq_options: {breakpoints: BREAKPOINTS}});

Vue.mixin(NotificationsMixin);
Vue.mixin(MediaQueryGlobalMixin);

const rolesSchema = {
    [USER]: UserApp,
    [ADMIN]: AdminApp,
    [ACCOUNT]: AccountApp,
};

const role = authStorage.getUserRole;

(async () => {
    if (role) {
        await authUser.init();
    }

    new Vue(role ? rolesSchema[role] : LoginApp).$mount('#app');
})();
