import i18n from '@/js/plugins/i18n';
import createRouter from '@/js/helpers/createRouter';
import ROUTE_NAMES from '@/js/domains/structures/common/RouteNames';

const routes = {
    redirect: [
        {
            path: '*',
            redirect: {name: ROUTE_NAMES.flows},
        },
    ],

    main: [
        {
            path: `/flows`,
            name: ROUTE_NAMES.flows,
            meta: {
                title: i18n.tc('flow', 2),
            },
            component: () => import('@/pages/user/flows/index/Flows'),
        },
        {
            path: `/countries`,
            name: ROUTE_NAMES.countries,
            meta: {
                title: i18n.tc('country', 2),
            },
            component: () => import('@/pages/user/countries/index/Countries'),
        },
        {
            path: `/fulfillments`,
            name: ROUTE_NAMES.fulfillments,
            meta: {
                title: i18n.tc('fulfillment', 2),
            },
            component: () => import('@/pages/user/fulfillments/index/FulFillments'),
        },
        {
            path: `/affiliates`,
            name: ROUTE_NAMES.affiliates,
            meta: {
                title: i18n.tc('affiliate', 2),
            },
            component: () => import('@/pages/user/affiliates/index/Affiliates'),
        },
        {
            path: `/call-center`,
            name: ROUTE_NAMES.callCenter,
            meta: {
                title: i18n.tc('call_center'),
            },
            component: () => import('@/pages/user/call-center/index/CallCenter'),
        },
        {
            path: `/products`,
            name: ROUTE_NAMES.products,
            meta: {
                title: i18n.tc('product', 2),
            },
            component: () => import('@/pages/user/products/index/Products'),
        },
        {
            path: `/user/settings`,
            name: ROUTE_NAMES.userSettings,
            meta: {
                title: i18n.tc('settings'),
            },
            component: () => import('@/js/domains/settings/index/Settings'),
        },
        {
            path: `/info-center`,
            name: ROUTE_NAMES.infoCenter,
            meta: {
                title: `${i18n.tc('info')} ${i18n.tc('center')}`,
            },
            component: () => import('@/pages/user/settings/info-center/index/InfoCenter'),
        },
        {
            path: `/logs/http-client-errors-logs`,
            name: ROUTE_NAMES.httpClientErrorsLogs,
            meta: {
                title: i18n.tc('clientErrorsLogs'),
            },
            component: () => import('@/pages/user/settings/http-client-errors-logs/index/HttpClientErrorsLogs.vue'),
        },
        {
            path: `/crm`,
            name: ROUTE_NAMES.crm,
            meta: {
                title: i18n.tc('crm'),
            },
            component: () => import('@/pages/user/crm/index/Crm'),
        },
        {
            path: `/statuses-report`,
            name: ROUTE_NAMES.statusesReport,
            meta: {
                title: i18n.tc('status', 2),
            },
            component: () => import('@/pages/user/report/status-report/index/StatusReport'),
        },
        {
            path: `/logistics-report`,
            name: ROUTE_NAMES.logisticsReport,
            meta: {
                title: i18n.tc('logistic', 2),
            },
            component: () => import('@/pages/user/report/logistic-report/index/LogisticReport'),
        },
        {
            path: `/empty`,
            name: ROUTE_NAMES.empty,
            component: () => import('@/pages/user/empty-page/EmptyPage'),
        },
    ],

    nested: [
        {
            path: '/fulfillments/products',
            name: ROUTE_NAMES.fulfillmentProducts,
            meta: {
                title: i18n.tc('product', 2),
                parent: ROUTE_NAMES.fulfillments,
            },
            component: () => import('@/pages/user/products/product/index/FulfillmentProducts.vue'),
        },
        {
            path: '/fulfillments/couriers',
            name: ROUTE_NAMES.fulfillmentCouriers,
            meta: {
                title: i18n.tc('courier', 2),
                parent: ROUTE_NAMES.fulfillments,
            },
            component: () => import('@/pages/user/fulfillments/warehouse/warehouseCouriers/index/WarehouseCouriers.vue'),
        },
        {
            path: `/targets/:hash`,
            name: ROUTE_NAMES.targets,
            meta: {
                title: i18n.tc('target', 2),
                parent: ROUTE_NAMES.flows,
            },
            component: () => import('@/pages/user/flows/targets/Targets'),
        },
        {
            path: `/crm/order-log/:hash`,
            name: ROUTE_NAMES.orderLog,
            meta: {
                title: `${i18n.tc('order')} ${i18n.tc('log')}`,
                parent: ROUTE_NAMES.crm,
            },
            component: () => import('@/pages/user/crm/order-log/index/OrderLog.vue'),
        },
    ],
};

const router = createRouter(routes);

export default router;
