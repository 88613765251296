import BaseModel from '@/js/models/BaseModel';
import INTEGRATION_TYPES from '@/js/domains/Integration/Structures/integration_types';

class CallCenterClass extends BaseModel {
    get entity() {
        return 'callCenterClasses';
    }

    generateApiKey() {
        return this.api.post(`/api/integration/api-key/generate`, {type: INTEGRATION_TYPES.callCenter});
    }

    getAutocompleteList(params) {
        return this.api.get(`${this.baseUrl}/getAutocompleteList`, {params});
    }
}

export default new CallCenterClass();
