import {
    DEFAULT_FILTER_NAME,
    FILTER_SEARCH_TEMPLATE,
    FILTER_TEMPLATE,
    SEARCH_FILTER_NAME,
} from '@/js/domains/structures/common/filters';

const queryBuilder = ({query = {}, pagination, sorting, filters = {}}) => {
    let query_string = '';
    const include_param = 'include';
    const is_include = query && query.hasOwnProperty(include_param);
    const include_keys = is_include ? Object.keys(query.include) : [];

    if (is_include) {
        query_string = include_param;
        query_string += _getIncludes(include_keys, query);
    }

    query_string += _getParams(query);

    if (pagination) {
        query_string += `&page[number]=${pagination.number}&page[size]=${pagination.size}`;
    }

    if (sorting && sorting.sort_by) {
        query_string += `&sort=${sorting.sort_desc ? '-' : '+'}${sorting.sort_by}`;
    }

    query_string = is_include ? query_string : query_string.substring(1);

    query_string += _getFilters(filters);

    return query_string ? `?${query_string}` : query_string;
};

function _getIncludes(include_keys, query) {
    return include_keys.map((key, i) => !i ? `=${key}${_getNestedInclude(query.include[key])}` : `,${key}`).join('');
}

function _getNestedInclude(key) {
    return typeof key === 'string' ? '.' + key : '';
}

function _getParams(obj) {
    let string = '';

    for (const [key, val] of Object.entries(obj)) {
        if (Array.isArray(val)) {
            if (key === 'count') {
                string += `&${key}=${val.toString()}`;
            } else {
                string += `&fields[${key}]=${val.toString()}`;
            }
        } else if (key === 'params') {
            for (let [k, v = ''] of Object.entries(val)) {
                string += `&${k}=${v}`;
            }
        } else if (typeof val === 'string') {
            continue;
        } else {
            string += _getParams(val);
        }
    }

    return string;
}

function _getFilters(filters) {
    let string = '';

    for (let [key, val] of Object.entries(filters)) {
        if (val === undefined || val === null) {
            continue;
        }

        if (key.includes('date')) {
            string += `&${key}=${val}`;
        } else {
            const filters = key.split('|').map(k => `[${k}]`);

            if (filters.length === 1) {
                filters.push(DEFAULT_FILTER_NAME);
            }

            const is_search = filters.find((name) => name === SEARCH_FILTER_NAME);
            const filter_query_template = is_search ? FILTER_SEARCH_TEMPLATE : FILTER_TEMPLATE;

            string += filter_query_template
                .replace('{filter_name}', filters.join(''))
                .replace('{filter_value}', encodeURIComponent(val.toString()));
        }

    }
    return string;
}

export default queryBuilder;
