<script>

export default {
    name: 'LoginApp',
    data: () => ({}),
    beforeCreate() {
        document.body.className = 'with-image';
    },
    created() {
        document.title = `${this.$t('login')} | ${this.$t('crm_name')}`;
    },
};
</script>

<template>
  <div id="app" class="login-app">
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card overflow-hidden">
              <div class="row">
                <div class="col-12">
                  <div class="pt-4 pl-4 pr-4">
                    <h4 class="text-center pt-3 mb-0">{{ $t('crm_name') }}</h4>
                  </div>
                </div>
              </div>
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.with-image {
  background-image: url('~@/images/login_page_bg.jpeg');
  background-size: cover;
  background-attachment: fixed;
}
</style>
