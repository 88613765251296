<script>
import ModalMixin from '@/js/mixins/ModalMixin';
import {mapActions, mapGetters} from 'vuex';
import {SelectItem} from '@frontend/components';

export default {
    name: 'ChangeCompanyModal',
    components: {SelectItem},
    mixins: [
        ModalMixin,
    ],
    props: {
        is_visible: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            selected_company: {},
            modal_id: 'change-company-modal',
        };
    },
    computed: {
        ...mapGetters('UserModule', ['companies', 'company_hash', 'available_companies']),
    },
    methods: {
        ...mapActions('UserModule', ['changeCurrentCompany']),
    },
};
</script>

<template>
  <div>
    <b-modal
      :title="`${$tc('change')} ${$tc('company').toLowerCase()}`"
      :id="modal_id"
      @ok="changeCurrentCompany(selected_company.hash)"
      @hidden="close"
      @show="selected_company = available_companies[0]"
      ok-variant="success"
      body-class="pb-0"
      no-close-on-backdrop
      ok-only
    >
      <form ref="form">
        <b-form-group :label="$tc('company')">
          <select-item v-model="selected_company" :options="available_companies" search></select-item>
        </b-form-group>
      </form>
      <template #modal-ok>{{ $t('apply') }}</template>
    </b-modal>
  </div>
</template>
