import Company from '@/js/models/Company';
import {QUERY} from '@/js/domains/structures/common/filters';

export default {
    namespaced: true,
    state: {
        countries: [],
        countries_loading: false,
        countries_loaded: false,
    },
    getters: {
        countries: ({countries}) => {
            return _.map(countries, ({country: {hash, code, title}}) => ({hash, code, title}));
        },
    },
    mutations: {
        updateCountries(state, payload) {
            state.countries = payload;
            state.countries_loaded = true;
            state.countries_loading = false;
        },
        setCountriesLoading(state, payload) {
            state.countries_loading = payload;
        },
        setCountriesLoaded(state, payload) {
            state.countries_loaded = payload;
        },
    },
    actions: {
        updateCountries({dispatch, commit}, params = {}) {
            commit('setCountriesLoaded', false);
            dispatch('loadCountries', params);
        },
        async loadCountries({commit, state}, params = {}) {
            if (state.countries_loading || state.countries_loaded) {
                return;
            }

            commit('setCountriesLoading', true);
            const {data} = await Company.getCompanyCountries({...params, ...QUERY});
            commit('updateCountries', data);
        },
    },
};
