import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import {Integrations} from '@sentry/tracing';

export default {
    init(user_hash) {
        const {VUE_APP_ENV, VUE_APP_SENTRY_DSN} = process.env;

        if (VUE_APP_SENTRY_DSN) {
            Sentry.init({
                Vue,
                dsn: VUE_APP_SENTRY_DSN,
                integrations: [new Integrations.BrowserTracing()],
                environment: VUE_APP_ENV,
            });
            Sentry.setUser({id: user_hash});
        }
    },
};
