import BaseModel from '@/js/models/BaseModel';
import queryBuilder from '@/js/helpers/queryBuilder';

class User extends BaseModel {
    get entity() {
        return 'users';
    }

    generatePassword(hash) {
        return this.api.patch(`${this.baseUrl}/${hash}/generatePassword`);
    }

    getPermissions() {
        return this.api.get(`${this.baseUrl}/getCurrentPermissions`);
    }

    getAuthUser(query) {
        return this.api.get(`${this.baseUrl}/getAuthUser${queryBuilder(query)}`);
    }

    getUserProfile(hash) {
        return this.api.get(`${this.baseUrl}/${hash}/profile`);
    }

    updateUserProfile({hash, ...params}) {
        return this.api.put(`${this.baseUrl}/${hash}/profile`, params);
    }

    loginAsUser(hash) {
        return this.api.post(`${this.baseUrl}/loginAs`, {hash});
    }

    logoutAsUser(hash) {
        return this.api.post(`${this.baseUrl}/logoutFrom`, {hash});
    }

    changeStatus({hash, ...params}) {
        return this.api.put(`${this.baseUrl}/${hash}/changeStatus`, params);
    }

    changePassword({hash, ...params}) {
        return this.api.put(`${this.baseUrl}/${hash}/changePassword`, params);
    }

    attachCompany({hash, ...params}) {
        return this.api.put(`${this.baseUrl}/${hash}/attachCompany`, params);
    }

    removeFromCompany({hash, ...params}) {
        return this.api.put(`${this.baseUrl}/${hash}/detachCompany`, params);
    }

    getG2FASecretKey() {
        return this.api.get(`${this.baseUrl}/getG2FASecretKey`);
    }

    validateG2FAToken(params) {
        return this.api.post(`${this.baseUrl}/validateG2FAToken`, params);
    }

    enableG2FA(params) {
        return this.api.post(`${this.baseUrl}/enableG2FA`, params);
    }

    disableG2FA(params) {
        return this.api.post(`${this.baseUrl}/disableG2FA`, params);
    }

    changeCurrentCompany(company_hash) {
        return this.api.put(`${this.baseUrl}/changeCurrentCompany`, {company_hash});
    }
}

export default new User();

