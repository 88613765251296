import i18n from '@/js/plugins/i18n';
import router from '@/pages/account/router';
import ROUTE_NAMES from '@/js/domains/structures/common/RouteNames';

const menu_items = [
    {
        label: i18n.t('companies'),
        icon: 'building',
        link: router.resolve({name: ROUTE_NAMES.companies}).href,
    },
    {
        label: i18n.tc('user', 2),
        icon: 'person',
        link: router.resolve({name: ROUTE_NAMES.users}).href,
    },
];

export default menu_items;
