const ClickOutside = {
    bind(el, binding, vnode) {
        el.clickOutsideEvent = event => {
            if (binding.expression === null || binding.expression === false) {
                return;
            }

            if (!(el === event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };

        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
};

export default ClickOutside;
