import Affiliate from '@/js/models/Affiliate';

export default {
    namespaced: true,
    state: {
        affiliates: [],
        affiliates_loading: false,
        affiliates_loaded: false,
    },
    getters: {
        affiliates: ({affiliates}) => affiliates,
    },
    mutations: {
        updateAffiliates(state, payload) {
            state.affiliates = payload;
            state.affiliates_loaded = true;
            state.affiliates_loading = false;
        },
        setAffiliatesLoading(state, payload) {
            state.affiliates_loading = payload;
        },
        setAffiliatesLoaded(state, payload) {
            state.affiliates_loaded = payload;
        },
    },
    actions: {
        updateAffiliates({dispatch, commit}, params = {}) {
            commit('setAffiliatesLoaded', false);
            dispatch('loadAffiliates', params);
        },
        async loadAffiliates({commit, state}, params = {}) {
            if (state.affiliates_loading || state.affiliates_loaded) {
                return;
            }

            commit('setAffiliatesLoading', true);
            const {data} = await Affiliate.newIndex({sortBy: 'created_at', sortDirection: 'desc', ...params});
            commit('updateAffiliates', data);
        },
    },
};
