import System from '@/js/models/System';

export default {
    namespaced: true,
    state: {
        summary: {
            system_alerts: 0,
            failed_jobs: 0,
        },
        summary_loading: false,
        summary_loaded: false,
    },
    getters: {
        summary: ({summary}) => ({...summary, total: summary.system_alerts + summary.failed_jobs}),
    },
    mutations: {
        updateSummary(state, payload) {
            state.summary = payload;
            state.summary_loaded = true;
            state.summary_loading = false;
        },
        setSummaryLoading(state, payload) {
            state.summary_loading = payload;
        },
        setSummaryLoaded(state, payload) {
            state.summary_loaded = payload;
        },
        decreaseAlerts(state) {
            state.summary.system_alerts = state.summary.system_alerts - 1;
        },
        decreaseAllAlerts(state) {
            state.summary.system_alerts = 0;
        },
        decreaseFailedJobs(state) {
            state.summary.failed_jobs = state.summary.failed_jobs - 1;
        },
        decreaseAllFailedJobs(state) {
            state.summary.failed_jobs = 0;
        },
    },
    actions: {
        updateSummary: {
            root: true,
            async handler({dispatch, commit}) {
                commit('setSummaryLoaded', false);
                dispatch('loadSummary', null, {root: true});
            },
        },
        loadSummary: {
            root: true,
            async handler({commit, state}) {
                if (state.summary_loading || state.summary_loaded) {
                    return;
                }

                try {
                    commit('setSummaryLoading', true);
                    const {data} = await System.counters();
                    commit('updateSummary', data);
                } catch {
                } finally {
                    commit('setSummaryLoading', false);
                }
            },
        },
        decreaseAlerts({commit}) {
            commit('decreaseAlerts');
        },
        decreaseAllAlerts({commit}) {
            commit('decreaseAllAlerts');
        },
        decreaseFailedJobs({commit}) {
            commit('decreaseFailedJobs');
        },
        decreaseAllFailedJobs({commit}) {
            commit('decreaseAllFailedJobs');
        },
    },
};
