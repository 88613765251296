<script>
export default {
    name: 'PageHeader',
    props: {
        title: {
            type: String,
            default: '',
        },
        breadcrumbs: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
};
</script>

<template>
  <div class="page-title-box">
    <h5 class="page-title mb-0">{{ title }}</h5>

    <b-breadcrumb :items="breadcrumbs" class="m-0 d-none d-sm-flex"></b-breadcrumb>
  </div>
</template>

<style scoped lang="scss">
.page-title-box {
  overflow: hidden;
  padding-bottom: 0;
  max-width: 100%;

  .page-title {
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .breadcrumb {
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: nowrap;

    li {
      overflow: hidden;
      flex-shrink: 0;

      &:last-child {
        flex-shrink: 1;
      }
    }

    a, span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

</style>
