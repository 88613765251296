import i18n from '@/js/plugins/i18n';
import createRouter from '@/js/helpers/createRouter';
import ROUTE_NAMES from '@/js/domains/structures/common/RouteNames';

const routes = {
    redirect: [
        {
            path: '*',
            redirect: {name: ROUTE_NAMES.companies},
        },
    ],

    main: [
        {
            path: `/companies`,
            name: ROUTE_NAMES.companies,
            meta: {
                title: i18n.t('companies'),
            },
            component: () => import('@/pages/account/companies/index/Companies.js'),
        },
        {
            path: `/users`,
            name: ROUTE_NAMES.users,
            meta: {
                title: i18n.tc('user', 2),
            },
            component: () => import('@/pages/account/users/index/Users.js'),
        },
        {
            path: `/user/settings`,
            name: ROUTE_NAMES.userSettings,
            meta: {
                title: i18n.tc('settings'),
            },
            component: () => import('@/js/domains/settings/index/Settings'),
        },
    ],

    nested: [],
};

const router = createRouter(routes);

export default router;
