import Flow from '@/js/models/Flow';
import {QUERY} from '@/js/domains/structures/common/filters';

export default {
    namespaced: true,
    state: {
        flows: [],
        flows_loading: false,
        flows_loaded: false,
    },
    getters: {
        flows: ({flows}) => flows,
    },
    mutations: {
        updateFlows(state, payload) {
            state.flows = payload;
            state.flows_loaded = true;
            state.flows_loading = false;
        },
        setFlowsLoading(state, payload) {
            state.flows_loading = payload;
        },
        setFlowsLoaded(state, payload) {
            state.flows_loaded = payload;
        },
    },
    actions: {
        updateFlows({dispatch, commit}, params = {}) {
            commit('setFlowsLoaded', false);
            dispatch('loadFlows', params);
        },
        async loadFlows({commit, state}, params = {}) {
            if (state.flows_loading || state.flows_loaded) {
                return;
            }

            commit('setFlowsLoading', true);
            const {data} = await Flow.index({...params, ...QUERY});
            commit('updateFlows', data);
        },
    },
};
