import api from '@/js/bootstrap/axios/Axios';

class Auth {
    login(params = {}) {
        return api.post(`/api/auth/login`, params);
    }

    logout(params = {}) {
      return api.post(`/api/auth/logout`);
    }
}

export default new Auth();
