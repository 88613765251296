import BaseModel from '@/js/models/BaseModel';

class Flow extends BaseModel {
    get entity() {
        return 'flows';
    }
}

export default new Flow();

