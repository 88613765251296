import Vue from 'vue';
import Vuex from 'vuex';
import CallCenterModule from '@/js/store/modules/CallCenterModule';
import AffiliateModule from '@/js/store/modules/AffiliateModule';
import CompanyCountryModule from '@/js/store/modules/CompanyCountryModule';
import FailedJobModule from '@/js/store/modules/FailedJobModule';
import FlowModule from '@/js/store/modules/FlowModule';
import HeaderModule from '@/js/store/modules/HeaderModule';
import UserModule from '@/js/store/modules/UserModule';
import FiltersManagerModule from '@/js/store/modules/FiltersManagerModule';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        AffiliateModule,
        CallCenterModule,
        CompanyCountryModule,
        FailedJobModule,
        FlowModule,
        HeaderModule,
        UserModule,
        FiltersManagerModule,
    },
    strict: process.env.NODE_ENV !== 'production',
});
