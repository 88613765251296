const NotificationsMixin = {
    methods: {
        $showSuccessMessage(message = this.$tc('success')) {
            this.$notification.success(message);
        },
        $showErrorMessage(message = this.$tc('error')) {
            this.$notification.error(message);
        },
    },
};

export default NotificationsMixin;
